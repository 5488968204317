<template>
  <div class="container-base padding-myproj-container">
    <icon-page-header
      :icon="icon"
      :title="$t(title)"
      :sub-title="$t(subtitle)"/>
    <div class="col-11">
      <b-row >
          <div class="projects-totalizers-container">
            <p class="p-numbers projects-totalizers-value ta-center openSans-400 fz-27">{{total_projects}}</p>
            <p class="projects-totalizers-label openSans-100 fz-16">{{ $t('myProjects.totalizers.projects') }}</p>
          </div>
          <div class="projects-totalizers-container">
            <p class="p-numbers projects-totalizers-value ta-center openSans-400 fz-27">{{ingested_files}}</p>
            <p class="projects-totalizers-label openSans-100 fz-16">{{ $t('myProjects.totalizers.ingestedFiles') }}</p>
          </div>
          <div>
            <p class="p-numbers projects-totalizers-value ta-center openSans-400 fz-27">{{pipelines_used}}</p>
            <p class="projects-totalizers-label openSans-100 fz-16">{{ $t('myProjects.totalizers.liPipelines') }}</p>
          </div>
    </b-row>
    </div>

    <br>
    <b-row>
      <b-col>
        <div v-if="projects_newly.length > 0">
          <h3 class="myproj-group-title text-left">{{ $t('myProjects.general.newlyCreatedProjects') }}</h3>
          <my-projects-list
            :projects="projects_newly"
            :primaryAction="$t('general.actions.viewproject')"/>
        </div>
        <div v-if="projects_finish.length > 0">
          <h3 class="myproj-group-title text-left">{{ $t('myProjects.general.finishSettingUp') }}</h3>
          <my-projects-list
            :projects="projects_finish"
            :primaryAction="$t('general.actions.viewproject')"/>
        </div>
      </b-col>
    </b-row>

    <div class="mt-3" >
      <b-pagination v-if="total_projects > 0"
        v-model="currentPage"
        @change="changeHandler"
        align="center"
        :total-rows="total_projects"
        :per-page="pageSize"
        first-number
        last-number></b-pagination>
    </div>

  </div>
</template>

<script>
import IconPageHeader from "@/modules/common/components/IconPageHeader";
import MyProjectsList from "@/modules/projects/components/MyProjectsList";
import ProjectService from '@/modules/projects/services/ProjectService'

export default {
  name: "MyProjects",
  components: { MyProjectsList, IconPageHeader },
  data() {
    return {
      icon: 'icon_myprojects.svg',
      subtitle: 'myProjects.header.subtitle',
      title: 'myProjects.header.title',
      projects: [],
      total_projects: 0,
      ingested_files: 0,
      pipelines_used: 0,
      projects_newly: [],
      projects_finish: [],
      pageSize: 5,
      currentPage: 1
    }
  },
  methods: {
    initializeLists() {
      let filters = {
        start: (this.currentPage - 1) * this.pageSize,
        quantity: this.pageSize
      }
      ProjectService.search(filters).then(response => {
          this.projects_newly = []
          this.projects_finish = []
          this.projects = response.data.projects
          this.total_projects = response.data.total_entries
          this.ingested_files = response.data.count_documents
          this.pipelines_used = response.data.count_li_pipelines

          let end = new Date(Date.now())
          this.projects.forEach(project => {
            let created = new Date(project.created)
            project.path = `/${this.$route.params.bot}/projects/${project.id}`
            if((end - created) <= 86400000) {
              this.projects_newly.push(project)
            } else {
              this.projects_finish.push(project)
            }
          })
        }
      )
    },
    changeHandler(value) {
      this.currentPage = value
      this.initializeLists()
    }
  },
  created() {
    if (this.$store.getters.hasSelectedInstance) {
      this.initializeLists()
    }
  },
  computed:{
    currentInstance: function() {return this.$store.getters.getInstance}
  },
  watch: {
    currentInstance : function() {
      this.initializeLists()
    }
  }

}
</script>

<style scoped>
.padding-myproj-container{
  padding-right:41px;
}

.myproj-group-title{
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151515;
}
</style>