<template>
  <div>
    <div v-for="project in projects" :key="project.id">
      <project-list-card :project="project" :primary-action="primaryAction"></project-list-card>
    </div>
  </div>
</template>

<script>
import ProjectListCard from "@/modules/projects/components/ProjectListCard";

export default {
  components: { ProjectListCard },
  props: {
    projects: Array,
    primaryAction: String
  }
}
</script>