<template>
  <b-card class="mb-2 myproj-card">
    <b-row class="d-flex myproj-upperrow">
      <span v-if="isDayAgo(project)" class="li-us-glosary-title openSans-400 fz-11 span-new-padding">{{ $t('myProjects.cards.new') }}</span>
      <span class="library-card-date openSans-400 fz-12 mx-3">Created {{ project.created | formatTime }}</span>
      <div class="d-flex align-items-center" >
        <span v-if="project.created" class="library-card-date openSans-400 fz-12 mx-2">{{ $t('myProjects.cards.liUsed') }}</span>
        <span v-if="pipelineConfigsData['INGESTION'] > 0">
          <img class="pipelines-used-icons" :src="'/assets/images/icon_li_table_ingestion.svg'" />
          <span class="library-card-date openSans-400 fz-12 mx-2 pipelines-used-label">
            {{ $t('myProjects.cards.ingestion') }} <span v-if="pipelineConfigsData['INGESTION'] > 1">({{ pipelineConfigsData['INGESTION'] }})</span>
          </span>
        </span>
        <span v-if="pipelineConfigsData['LANGUAGE_INTELIGENCE'] > 0">
          <img class="pipelines-used-icons" :src="'/assets/images/icon_li_table_ontology.svg'" />
          <span class="library-card-date openSans-400 fz-12 mx-2 pipelines-used-label">
            {{ $t('myProjects.cards.ontologies') }}
            <span v-if="pipelineConfigsData['LANGUAGE_INTELIGENCE'] > 1">({{ pipelineConfigsData['LANGUAGE_INTELIGENCE'] }})</span>
          </span>
        </span>
      </div>
    </b-row>
    <b-row class="myproj-lowerrow">
      <div class="text-left myproj-projectname" @click="projectRoute(project.path)" >{{ project.project_name }}</div>
      <b-button-toolbar>
        <b-button class="button-primary openSans-600 fz-12 ta-center btn-primary-myproj" :to="project.path">
          <span>{{primaryAction}}</span>
        </b-button>
        <b-dropdown class="btn-secondary-myproj" disabled text="Apply Language Intelligence">
          <b-dropdown-item>Item 1</b-dropdown-item>
          <b-dropdown-item>Item 2</b-dropdown-item>
          <b-dropdown-item>Item 3</b-dropdown-item>
        </b-dropdown>
      </b-button-toolbar>
    </b-row>
  </b-card>
</template>

<script>
import moment from 'moment'
import tools from '@/modules/common/utils/tools.js'

export default {

  name: 'ProjectListCard',

  props: {
    project: Object,
    primaryAction: String
  },
  data () {
    return {
      pipelineConfigsData: {
        'INGESTION': 0,
        'LANGUAGE_INTELIGENCE': 0
      }
    }
  },
  filters: {
    formatTime: tools.formatTime
  },
  methods: {
    projectRoute(route) {
      this.$router.push(route)
    },
    isDayAgo(project) {
      let end = new Date()
      let timeLocal = moment(end).format()
      return !moment.utc(project.created).local().isBefore(timeLocal, 'day')
    },
    countPipelineConfigs() {
      this.project.pipeline_configs.forEach(pipelineConfig => {
        this.pipelineConfigsData[pipelineConfig.type] += 1
      })
    }
  },
  created() {
    this.countPipelineConfigs()
  }
}
</script>

<style lang="css" scoped>

  .span-new-padding {
    padding-left: 10px;
    margin: auto 0px;
  }

</style>