<template>
  <div>
    <b-row :class="isEta ? 'mt-0' : ''" class="section-header ta-left">
      <div :class="is_mobile ? 'col-2' : 'col-1'" class="icon-header-center-align" >
        <img v-if="icon" :src="`/assets/images/${icon}`"/>
      </div>
      <div :class="is_mobile ? 'col-10' : 'col-9' " class="section-title-container">
        <h1 class="section-title openSans-100 fz-28">{{ title }}</h1>
        <p class="section-description openSans-100 fz-16">{{ subTitle }}</p>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {

  name: 'IconPageHeader',
  props: {
    icon: String,
    title: String,
    subTitle: String,
    isEta: Boolean
  },
  computed: {
    is_mobile() {
      const isMobile = window.matchMedia("only screen and (max-width: 1024px)")
      return isMobile.matches ? true : false
    }
  },
}
</script>

<style lang="scss">

    @media (min-width: 320px) and (max-width: 1024px) { 
        div.section-header {
          margin-top: 20px !important;
        }
    }
</style>
